@font-face {
  font-family: bix-font;
  src: url(../../font/SF-Pro-Display-Regular.otf);
}

.files-main-wrapper {
  flex-basis: 70%;
}

.right-up {
  float: top;
}
.app-logo {
  margin: auto;
  height: 52px;
  width: 158px;
  background: black;
  border-radius: 8px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  float: left;
  align-self: center;
  font-size: 35px;
  margin-top: 1%;
  margin-left: 2%;
}

.c-gradient {
  background: -webkit-linear-gradient(left, #07a326, #dea60d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.a-gradient {
  background: -webkit-linear-gradient(left, #dea60d, #3799f4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.right-side {
  float: right;
  background-color: #f6fbff;
  width: 100%;
  height: 93vh;
  color: #303575;
  font: normal normal 00 14px bix-font;
  border-radius: 4px;
}

.name {
  float: left;
  position: absolute;
  margin-top: 3%;
  margin-left: 2%;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

.icons-search {
  position: absolute;
  float: left;
  height: 18px;
  width: 25px;
  align-self: center;
  cursor: pointer;
  margin-left: -3%;
  margin-top: 4.5%;
}

.icons-dashboard {
  float: right;
  height: 25px;
  width: 25px;
  align-self: center;
  cursor: pointer;
  margin-right: 5%;
  margin-top: -1.5%;
}

/* .middle-top {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 14px;
  height: 7vh;
  background-color: #f7f7f7;
} */

.middle-folder {
  font: normal normal 600 11px bix-font;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  margin-top: 17px;
  margin-left: 10px;
  float: left;
  display: flex;
}

.middle-folder-icon {
  width: 16.5px;
  float: left;
  margin-top: 15px;
  margin-left: 10px;
}

.right-middle {
  height: 91%;
  width: 69.5%;
  float: left;
  margin-top: 0%;
  margin-left: -0.5%;
  background-color: #f6fbff;
  border-radius: 16px;
  opacity: 1;
}
.images {
  position: relative;
  border-radius: 8px;
  opacity: 1;
  width: 15.5%;
  height: 21vh;
  margin-top: 5%;
  margin-left: 5%;
  cursor: pointer;
  left: 1%;
  background-color: #c2dbf1;
  float: left;
  border: 1px solid #afcbe4;
}
.anim-container {
  height: 40vh;
}

.jjj {
  background-color: yellow;
  height: 20vh;
}

.lottie {
  margin-top: 135px;
}

.empty-folder {
  display: flex;
  justify-content: center;
  color: #000;
  position: relative;
  margin: auto;
  font: bix-font;
  font-size: 20px;
  margin-top: 10px;
  letter-spacing: 0px;
  color: #8a8a8a;
  opacity: 1;
  font-weight: 450;
}

.empty-folder2 {
  display: flex;
  justify-content: center;
  color: #000;
  position: relative;
  margin: auto;
  font: bix-font;
  font-size: 12px;
  margin-top: 5px;
  letter-spacing: 0px;
  color: #8a8a8a;
  opacity: 1;
  font-weight: 450;
}

.file-more {
  cursor: pointer;
  float: right;
  margin-right: 5%;
  width: 20px;
  z-index: 1;
}

.main-folders-icon {
  width: 60%;
  height: 45%;
  margin-left: 19%;
  margin-top: 1%;
}

.main-folder-name {
  float: left;
  color: black;
  margin-left: 17%;
  font-family: bix-font;
  font-size: 12px;
}

.main-folder-size {
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 12%;
  color: rgba(8, 11, 216, 0.89);
  font-weight: bolder;
}

.main-folder-files {
  float: left;
  color: rgb(150, 144, 144);
  margin-left: 18%;
  margin-top: -5%;
  font-size: 9px;
  font-family: Arial, Helvetica, sans-serif;
}

.topbar-parent {
  width: 100%;
  height: 10vh;
  display: flex;
}

.topbar-parent-search {
  width: 50%;
}

.topbar-parent-view {
  width: 10%;
}

.topbar-parent-type {
  width: 20%;
}

/* .topbar-parent-create-new {
  display: flex;
  width: 30%;
  text-decoration: none;
  color: #ffffff;
} */


.create-new {
  width: 110px;
  background-color: #303575;
  text-align: center;
  margin-top: 8%;
  margin-left: 28%;
  font-size: 13px;
  height: 27.5px;
  color: #e9e9e9;
  cursor: pointer;
  font-weight: bolder;
  border-radius: 4px;
  font: normal normal 500 14px bix-font;
  padding-top: 4px;
}
.create-new-down-arrow {
  margin-top: 8.1%;
  margin-left: -1.7%;
  width: 30px;
  background-color: #303575;
  text-align: center;
  height: 27.5px;
  color: #000;
  cursor: pointer;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-left: #f6fbff 0.5px solid;
  font-weight: bolder;
}

.create-new-folder-button {
  margin-top: 8.1%;
  margin-left: -1.7%;
  width: 30px;
  background-color: #f6fbff;
  text-align: center;
  height: 27.5px;
  color: #000;
  cursor: pointer;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-left: #f6fbff 0.5px solid;
  font-weight: bolder;
}

.downarrow {
  height: 17px;
  width: 17px;
  padding-top: 5px;
}

.view-p {
  color: #303575;
  font-size: 11px;
  margin-left: 49%;
  margin-top: 35%;
  font-family: bix-font;
  font-weight: normal;
}

.view-type {
  margin-top: 14%;
  margin-left: 17%;
  width: 25px;
  cursor: pointer;
}

.search-bar2 {
  margin-top: 5%;
  margin-left: 7%;
  width: 290px;
  border-radius: 3px;
  text-align: left;
  background-color: #ffffff;
  border: none;
  height: 32px;
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal normal 11px bix-font;
  padding-left: 15px;
  font-weight: 600;
}
::placeholder {
  color: #9d9d9d;
}

.middle {
  width: 100%;
  background-color: #ffffff;
  height: 93vh;
}

.parent-div {
  height: 83.7vh;
  background-color: #f6fbff;
  border-radius: 16px;
  opacity: 1;
  width: 98%;
  margin: 1%;
}
.upload-files {
  width: 100%;
  height: 100vh;
}

/* .drop_zone {
  border: 5px solid blue;
  width:  200px;
  height: 100px;
} */


::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  border-radius: 5px;
  background-color: #c9c9c9;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9c9c9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(148, 147, 147);
}

.uploaded-Files {
  width: 100%;
  overflow-y: scroll;
  height: 67.5vh;
  margin-top: 1%;
}

.middle-line {
  width: 100%;
  height: 0.2vh;
  background-color: #dbdbdb;
}

.img-div {
  width: 110px;
  height: 19.5vh;
  background-color: #f6fbff;
  /* margin-left: 3.8%; */
  margin: 7px 1%;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid #dbdbdb;
}

.files-Wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 96%;
  margin-left: 1.5%;
}

.img-div:hover {
  background-color: #e4ebf8;
}

.sub-folder-selected {
  background-color: #e4ebf8;
  width: 110px;
  height: 19.5vh;
  margin: 7px 1%;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid #2680EB;
}

.folder-icon {
  width: 78px;
  position: relative;
  margin-top: 0%;
  margin-left: 16%;
}

.img {
  width: 55px;
  position: relative;
  margin-top: -15%;
  margin-left: 26%;
}

.file-tag {
  text-align: center;
  align-self: center;
  padding-top: 3px;
  width: 40px;
  height: 2.5vh;
  background-color: #5177d8;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  margin-top: -21px;
  margin-left: 20px;
  position: relative;
  z-index: 0;
  text-transform: uppercase;
}

.right-modal-div {
  width: 750px;
  height: 450px;
  background-color: #07a326;
}
.file-namee {
  color: #35127a;
  margin-left: 12%;
  padding-top: 9%;
  margin-top: -0.5%;
  font-size: 12.5px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 79%;
  font-family: "BIX-FONT";
  text-align: center;
  font-weight: bolder;
}

.last-opened {
  color: #9d9d9d;
  font-size: 10px;
  margin-left: 30%;
  margin-top: -5%;
}


.upload-icon {
  width: 8%;
  float: right;
  margin-top: -13%;
  
}

.menufirst {
  background-color: rgb(34, 33, 33);
  width: 220%;
  height: 5vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: center;
  text-align: center;
  padding-top: 2px;
  border: rgb(199, 192, 192) solid 1px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.menus {
  background-color: rgb(34, 33, 33);
  width: 220%;
  height: 5vh;
  text-align: center;
  padding-top: 2px;
  border: rgb(199, 192, 192) solid 1px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.menulast {
  background-color: rgb(34, 33, 33);
  width: 220%;
  height: 5vh;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  padding-top: 2px;
  border: rgb(199, 192, 192) solid 1px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.menufirst :hover {
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 15.9px;
}

.menus :hover {
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}

.menulast :hover {
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}


input:focus {
  outline: none;
}

.line1 {
  width: 93.1%;
  height: 1px;
  margin-left: 2.5%;
  background-color: rgb(185, 184, 184);
  margin-top: 28.5%;
}

.folder {
  display: grid;
  position: relative;
  border-radius: 20px;
  width: 12.5%;
  height: 21vh;
  margin-top: 8%;
  margin-left: 5%;
  cursor: pointer;
  left: 1%;
  background-color: #c2dbf1;
  float: left;
  border: 1px solid #afcbe4;
}

.folders-icon {
  width: 55%;
  height: 55%;
  margin-left: 23%;
  margin-top: 18%;
}

.divmainfolder {
  background-color: rgb(148, 46, 46);
  width: 50%;
}

.list {
  font-size: 50px;
  position: absolute;
}

.app-logo-div {
  width: 20%;
}

.newfiles {
  color: #000;
  margin-left: 20px;
}

.upload-file-name {
  margin-top: 5.5%;
  margin-left: 10%;
  width: 87%;
}

.folder-icon-wrapper {
  margin-top: 25px;
  width: 110px;
}

.file-icon-wrapper {
  margin-top: 10px;
  width: 110px;
}

@media (min-width: 1695px) {
  .middle-top {
    /* width: 695px; */
  }
}

@media (max-width: 1440px) {
  .middle-top {
    /* width: 695px; */
  }
}




.Uploadbuttons{
  margin-top: 20px;
  width: 92px;
  background-color: #303575;
  text-align: center;
  font-size: 15px;
  height: 32px;
  color: #e9e9e9;
  cursor: pointer;
  font-weight: 500;
  border-radius: 3px;
  font: normal normal 00 14px bix-font;
  justify-content: center;
}