@font-face {
  font-family: bix-font;
  src: url(../../font/SF-Pro-Display-Regular.otf);
}

.left-side {
  float: left;
  background-color: #ffffff;
  /* width: 474px; */
  flex-basis: 30%;
  height: 93vh;
  border-right: 2px solid #dbdbdb;
}

.up-div {
  height: 5vh;
  margin-top: -8%;
  display: flex;
  justify-content: end;
  margin-right: 20px;
  padding-top: 20px;
  

  
}
.new-button {
  width: 80px;
  background-color: #303575;
  text-align: center;
  font-size: 15px;
  height: 26px;
  color: #e9e9e9;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  font: normal normal 00 14px bix-font;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  
}

.search-bar-left1 {
  padding-left: 20px;
  margin-top: 5%;
  margin-left: 4%;
  width: 300px;
  text-align: left;
  border: none;
  height: 28px;
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal normal 11px bix-font;
  padding-left: 15px;
  font-weight: 600;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #9d9d9d;
}

/* .search-bar-left::placeholder {
  padding-left: 0.5%;
} */

.search-div {
  height: 5vh;
}

.icons-search {
  position: absolute;
  margin-left: 40px;
  margin-top: 28px;
  float: left;
}
.h1-left {
  text-align: left;
  font: normal normal normal 12px bix-font;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  text-decoration: none;
  padding-top: 0px;
  font-weight: lighter;
  display: unset;
}


.h1-left-folder {
  font-size: 13px;
  align-self: center ;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin-left: 6px;
  width: 90%;
  font-weight: lighter;
  display: unset;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-left: 2px;
}
.hover-leftside:hover{
  background-color: #3799f4;

}



.img-folder-arrow {
  width: 18px;
  margin-right: 1.5%;
}

.img-folder-arrow-down {
  width: 18px;
  margin-right: 1.5%;
  transform: rotate(90deg);
}

.h1-left-first {
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  text-align: left;
  width: 100%;
  margin-left: -45px;
  padding-top: 9px;
  font-weight: heavy;
  list-style-type: none;
}

.addbutton{
  padding-right: 20px;
}

.left-nav {
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  padding-top: 10px;
  user-select: none;
  flex-direction: column;
  
  
}

.lable{
  text-align: left;
  width: 50%;
  background-color: Transparent;
  border-color: #1e1e1e;
  border: none; 
  padding-left: 10px;
  
}

.box{
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 25px;
  
}

.line-left {
  width: 100%;
  height: 0.2vh;
  background-color: #dbdbdb;
}

.leftt-create-new {
  text-align: center;
  height: 40px;
  width: 45%;
  margin-left: 17%;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
}

.leftt:hover {
  /* background-color: rgba(0, 0, 0, 0.08); */
  border-bottom: 2px solid #3799f4;
}

.left-div {
  margin-top: 12%;
}

.icons-left {
  float: left;
  align-self: center;
  margin-left: 4%;
  height: 40px;
  width: 20px;
  align-self: center;
  cursor: pointer;
}

.button-popup {
  width: 15%;
  height: 4vh;
  background-color: #3799f4;
  border: none;
  border: #3799f4 1px solid;
  margin-top: 2%;
  margin-left: 67%;
  border-radius: 4px;
}

.folders {
  list-style-type: none;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  width: 88%;
  margin-left: 8%;
  border-radius: 5px;
}

.main-folder-selected {
  list-style-type: none;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  width: 88%;
  margin-left: 8%;
  border-radius: 5px;
  background-color: #e4ebf8;
}

.folders:hover {
  background-color: #e4ebf8;
}

.folder-div {
  overflow-y: scroll;
  height: 58vh;
  padding-left:16px;

}

.leftside-sub-folder {
  margin-left: 10%;
  padding-top: 20px;
}

.subfolder-selected {
  list-style-type: none;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  width: 88%;
  margin-left: 8%;
  border: none;
  height: 5vh;
  border-radius: 5px;
}

.leftside-main-folder{
  padding-top:20px;
}


.sub-folder-div {
  float: left;
}

@media screen and (min-width: 1695px) {
  .left-side {
    /* width: 320px; */
    flex-basis: 60%;
  }

  .new-button {
    margin-left: 59%;
  }

  .icons-search {
    margin-left: 30px;
    margin-top: 21px;
  }

  .line-left {
    margin-top: -25px;
  }

  
}
