.content {
    overflow: hidden;
  }
  
  .main-body{
    display: flex;
  }
  
  
  
  /* @media screen and (min-width: 1441px) {
    .content {
      width: 75%;
      margin: auto;
      padding-top: 20px;
      margin-bottom: 20px;
    }
  } */
  @media screen and (min-width: 1695px) {
    .content {
      width: 75%;
      margin: auto;
      padding-top: 20px;
      margin-bottom: 20px;
    }
    
  }
  /* @media screen and (min-width: 1921px) {
    .content {
      width: 55%;
      margin: auto;
      padding-top: 20px;
      margin-bottom: 20px;
    }
  } */