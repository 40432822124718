.body-top-bar {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  height: 6vh;
  border-bottom: #e4ebf8 2px solid;
}

.app-logo-simple {
  text-align: center;
  font: normal normal normal 25px/25px bix-font;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  float: left;
  margin-top: 0.5%;
  height: 1vh;
  margin-left: 35px;
  font-size: 19px;
}

.main-apps-div {
  width: 40%;
  margin-left: 35%;
  margin-top: 0.5%;
  left: 10%;
}

.main-apps {
  text-align: left;
  letter-spacing: 0px;
  color: #9d9d9d;
  opacity: 1;
  font-size: 12px;
  margin-top: 1.1%;
  margin-left: 0%;
  display: inline-table;
  padding-left: 4%;
  font-family: bix-font;
  font-weight: lighter;
}

.pro-pic-div-top1 {
  width: 8%;
  height: 6vh;
}

.pro-pic {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 55%;
  margin-top: 5px;
  background-color: #e4ebf8;
}

.login{
  display: flex;
  margin-left: 15%;

}

.signup{
  width: 90px; 
  border-radius: 2px;
  background-color: #2699fb;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-family: "SF Pro Display", sans-serif;
  letter-spacing: normal;
  line-height: 0.94;
  margin-top: 6%;
  font-size: 10px;
  border-width: 0px;
  height: 30px;
  
}



.signin{
  width: 90px;
  border-radius: 2px;
  background-color: #ffffff;
  color: #2699fb;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-family: "SF Pro Display", sans-serif;
  letter-spacing: normal;
  line-height: 0.9;
  margin-top: 6%;
  font-size: 10px;
  border-width: 2px;
  border-color: #2699fb;
  height: 30px;
  
  
}
.signIN{
 
  padding-left: 10px;
}
.signIN, .signUP{
  margin-top: 5px;
}

/* 
.line {
  width: 93.1%;
  height: 1px;
  margin-left: 2.5%;
  background-color: rgb(185, 184, 184);
  margin-top: 5.5%;
} */
