
.modal-content {
    border-left: 12px #3799F4 solid ;

    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    right: 0;
    bottom: 0;
    position: absolute;
    margin-right: 20px;
    margin-bottom: 10px;
    width: 22%;
    height: 11%;
    z-index: 10;
    display: flex;
  }

  .send-new-message-others{
      border-bottom-left-radius: blue 1px solid;
  }

  .img-div-modal {
      
    width: 90%;
    height: 10vh;
    margin-left: 5%;
    border-radius: 22px;
    display: flex;
  }
  
  .file-tag-popup-progress {
    text-align: center;
    padding-top: 3px;
    float: left;
    width: 38px;
    height: 1.9vh;
    color: white;
    background-color: #5177d8;
    font-size: 10px;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 40px;
    margin-left: -64px;
    position: relative;
  }

  .file-name-modal {
    float: right;
    width: 85%;
    height: 6.7vh;
    margin-top: 2%;
  }
  
  
  .file-namee-progress {
    color: #35127a;
    margin-left: 5%;
    margin-top: 6%;
    font-size: 12.5px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 28%;
    font-family: "BIX-FONT";
    text-align: center;
    font-weight: bolder;
  }
  
  .img-modal-progress {
    float: left;
    width: 89%;
    height: 7vh;
    margin-top: 25%;
    margin-left: 14%;

  }
  
  .progress-status-div {
    width: 10   0%;
    display: flex;
    margin-top: -4%;
  }
  
  .uploading-status {
      display: flex;
    margin-left: 5%;
    margin-top: -1px;
    color: #3799f4;
    font-size: 10px;
    font-style: italic;
    font-family: "BIX-FONT";
    font-weight: 300;
    float: left;
  }
  
  .close-progress-icon {
    float: right;
    width: 8%;
    margin-left: 69%;
    
  }
  
  .test-button {
    margin-left: 50px;
    width: 20%;
    height: 2vh;
    color: #000;
  }
  
  .file-tag-popup-progress {
    text-align: center;
    padding-top: 3px;
    float: left;
    width: 30px;
    height: 1.9vh;
    color: white;
    background-color: #5177d8;
    font-size: 7px;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    margin-top: 98%;
    margin-left: -54px;
    
  }

  .close-icon-progress{
    float: right;
    width: 3.5%;
  }
  








  