@font-face {
  font-family: bix-font;
  src: url(../../font/SF-Pro-Display-Regular.otf);
}

.sub-folder {
  margin-left: 12%;
  padding-top: 20px;  
}

