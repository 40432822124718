@import "../../../color.scss";

.gt-started {
    padding: 4%;
    width: 80%;
    border-radius: 28px;
    background-color: $get-started;
    color: $white;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    font-family: "Roboto";
    letter-spacing: normal;
    line-height: 0.94;
    font-size: 18px;
    border-width: 0px;
    height: 50px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  