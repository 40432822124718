.Image_Preview {
  box-shadow: 2px 6px 28px 0px rgb(0 0 0 / 25%) !important;
  border-radius: 10px !important;
  right: 0;
  bottom: 0;
  position: absolute;
  margin-right: 5%;
  margin-bottom: 1%;
  width: 87%;
  height: 97%;
  z-index: 10;
}

.image-preview-container {
  background-color: white;

  border-radius: 16px;
  height: 92vh;
  
  position: relative;
  
  
}
.video-preview{
  
  
  height: 70vh;
  width: 100%;
  margin: auto;
  object-fit: contain;
  border-radius: 30px;
  

  
}

.header {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  display: flex;
  flex: 1;
  height: 8.1vh;
  border-bottom: 0.5px solid #3799f4;
}

.right-container {
  flex: 2;
  display: flex;
  
}

.attachment-name {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  color: #3799f4;
  align-self: center;
  margin-left: 25px;
  padding-top: 25px;
}

.img-cont {
  background-color: #3799f4;
  width: 65px;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  margin-left: 3%;
}




.left-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.image-container {
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.download-img-a {
  height: 20px;
  width: 20px;
  align-self: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 10px;
  
}



.image-download {
  height: 25px;
  width: 25px;
  align-self: center;
  cursor: pointer;
  filter: invert(46%) sepia(98%) saturate(969%) hue-rotate(186deg)
    brightness(97%) contrast(97%);
  cursor: pointer;
  position: absolute;
  right: 7%;
  
}

.image {
    height: 30px;
    width: 40px; 
    align-self: center;
    cursor: pointer;
    margin-top: 10px;
    align-items: center;
    padding-left: 17px;
}



.imageClose {
    height: 18px;
    width: 18px;
    margin-top: 13px;
    filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(138deg)
      brightness(112%) contrast(101%);
    border-radius: 15%;
  }

.main-preview{ 
   height: 90%;
   width: 100%;
   
}

.image-preview{
  height: 75vh;
  width: 100%;
  margin: auto;
  object-fit: contain;
  border-radius: 30px;
  padding-top: 5%;
}

.pdf-container{
  margin: auto;
  width: 50%;
  
 

}
.control{
  position: fixed;
  display: flex;
  left: 45%;
  

}
.nextPage{
  width: 22px;
  height: 22px;
  transform: rotate(-90deg);
  padding-top: 12px;
}
.previousPage{
  width: 22px;
  height: 22px;
  transform: rotate(90deg);
  padding-top: 12px;
}
.pageText{
  
  padding-left: 7px;
  padding-right:7px;
  
}

.zoomIn{
  width: 30px;
  height: 30px;
  padding-left: 5px;
}
.zoomOut{
  width: 30px;
  height: 30px;
  padding-left: 5px;
}
.zoom_control{
  position: absolute;
  left: 90%;
  
}
