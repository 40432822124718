.close-s{
    height: 14px;
    width: 14px;
    cursor: pointer;
    align-self: center;

}

.close-m{
    height: 15px;
    width: 15px;
    cursor: pointer;
    align-self: center;

}

.close-l{
    height: 16px;
    width: 16px;
    cursor: pointer;
    // align-self: center;
    color: black;

}