@font-face {
  font-family: bix-font;
  src: url(../../../font/SF-Pro-Display-Regular.otf);
}


.search-bar-left {
  padding-left: 20px;
  margin-top: 5%;
  margin-left: 7%;
  width: 350px;
  text-align: left;
  border: none;
  height: 28px;
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal normal 11px bix-font;
  padding-left: 15px;
  font-weight: 600;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #9d9d9d;
}
