@font-face {
    font-family: bix-font;
    src: url(../../font/SF-Pro-Display-Regular.otf);
  }
.dropzone{
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    
}


label{
    width: 1500px;
    height: 900px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.779);
    z-index: 1;
   
}

.dropzone span{
    position: absolute;
    top: 35%;
    left: 30%;
    font-size: 72px;
    font-family: "bix-font";
    color:white;
}
