@font-face {
  font-family: bix-font;
  src: url(../../font/SF-Pro-Display-Regular.otf);
}

.details-parent {
  /* width: 26.8%; */
  flex-basis: 28%;
  background-color: #f7f7f7;
  height: 91vh;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin-left: 5px;
  margin-top: 5px;
  position: sticky;
  transition-duration: 2s;
  transition-delay: 15s;
}

.updiv-details {
  margin-top: 5px;
  height: 4vh;
  width: 100%;
}
.overview {
  text-align: left;
  font: normal normal bolder 12px bix-font;
  letter-spacing: 0px;
  font-size: 12px;
  color: #1e1e1e;
  float: left;
  margin-left: 5px;
}

.arrowOverview {
  float: left;
  margin-top: 3px;
  margin-left: 15px;
}

.line-details {
  width: 100%;
  height: 0.15vh;
  background-color: #dad8d8;
}

.img-div-details {
  border: none;
  width: 115px;
  margin-left: 80px;
  margin-top: 20px;
  height: 18.5vh;
  background-color: #cee7ff82;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid #dbdbdb;
}

.img-details {
  width: 55px;
  padding-top: 20px;
  position: relative;
  margin-top: -2%;
  margin-left: 26%;
}

.file-tag1 {
  text-align: center;
  align-self: center;
  padding-top: 3px;
  width: 40px;
  height: 2.5vh;
  background-color: #5177d8;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  margin-top: -21px;
  margin-left: 20px;
  position: absolute;
  z-index: 0;
}

.file-name-details {
  color: #35127a;
  margin-left: 12%;
  padding-top: 3%;
  margin-top: -0.5%;
  font-size: 12.5px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 79%;
  font-family: "BIX-FONT";
  text-align: center;
  font-weight: bolder;
}

.last-opened-details {
  color: #9d9d9d;
  font-size: 10px;
  margin-left: 30%;
  margin-top: 10%;
}

.line2-details {
  width: 90%;
  height: 0.2vh;
  margin-top: 5vh;
  margin-left: 4%;
  background-color: #dad8d8;
}

.access-details-name {
  text-align: left;
  font: normal normal 600 12px Proxima Nova;
  letter-spacing: 0px;
  color: #414141;
  opacity: 1;
  margin-left: 6%;
}

.pro-pic-div {
  display: flex;
}

.pro-pic-img{
  
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1;
}

.img-round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e4ebf8;
  margin-left: 15px;
  margin-top: 2px;
}

.line-img {
  width: 0.4%;
  height: 4.7vh;
  margin-top: 2.2px;
  margin-left: 10px;
  background-color: #dad8d8;
}

.img-round2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #303575;
  margin-left: 15px;
  display: flex;
  text-align: center;
}

.infinity-logo {
  width: 25px;
  margin: auto;
}

.line3-details {
  width: 90%;
  height: 0.2vh;
  margin-top: 3vh;
  margin-left: 4%;
  background-color: #dad8d8;
}

.details-options {
  width: 100%;
  height: 5vh;
}

.options {
  color: #1e1e1e;
  display: flex;
}

.options-last {
  display: flex;
}

.options1 {
  margin-left: 6%;
  text-align: left;
  font: normal normal normal 11px Proxima Nova;
  letter-spacing: 0px;
  color: #414141;
  opacity: 1;
  width: 20px;
}

.options2 {
  margin-left: 19%;
  text-align: left;
  font: normal normal normal 11px Proxima Nova;
  letter-spacing: 0px;
  color: #553c3c;
  float: right;
  width: 40%;
}

.options-last {
  width: 100%;
}

.options-description {
  margin-left: 6%;
  text-align: left;
  font: normal normal normal 11px Proxima Nova;
  letter-spacing: 0px;
  color: #414141;
  opacity: 1;
  width: 40%;
}

.edit-button {
  margin-left: 35%;

  text-align: left;
  font: normal normal normal 11px Proxima Nova;
  letter-spacing: 0px;
  color: #553c3c;
  float: right;
}
