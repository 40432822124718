.popup_front{
    
  margin-top: 25%;
  width: 92px;
  background-color: #303575;
  align-content: center;
  font-size: 15px;
  height: 32px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  font: normal normal 00 14px bix-font;
  justify-content: center;
  position: relative;
  display: flex;
  
}



.button{
    
    flex: 2;
    text-align: center;
    margin-bottom: 10%;
    border-right: 20px;
    margin-left: 9px;
    border-right:red;

    
}
.icon{
    flex: 1;
    margin-right: 5px;
}

.button,.icon{
    margin-top: 9px;
    color: #f0f0f6;
    
    
}