$white: #fff;
$loader-white: #f3f3f3;

$black: #000;
$text-black:#1e1e1e;
$small-text-black:#414141;
$login-header:#313131;
$head-name:#3e3e3e;

$primary-blue:#3799f4;
$sending-blue:#030303;
$buttn-blue:#2699fb;
$loader-gray:#989ca0;
$temp-blue:#aec8e0;
$light-blue-border:#65b4fe;
$border-bluline:#0094ff;
$get-started:#3770F4;
$folder-title-clr:#303575;
$img-type:#5177DB;

$light-gray:#f1f1f1;
$gray:#dbdbdb;
$selected-gray:#9f9f9f;
$form-gray:#efefef;
$dark-gray:#9d9d9d;
$white-gray:#dcdcdc;
$incoming-message-gray:#f5f5f5;
$border-gray:#999;
$sub-topic-gray:#707070;
$text-gray:#4a4a4a;
$slight-gray:#aaaaaa;
$typing-text-gray:#838282;
$bottom-border-gray:#c7c7c7;
$darker-gray:#666666;
$header-gray:#a7a7a7;
$box-shadow-gray:#4d4c4c;
$light-border-gray:#b1b1b1;
$box-bakground:#E8E8E8;
$border-light-gray:#f0f0f0 ;
$attachment-color:#bfbfbf;
$login-underline-gray:#B2B2B2;
$tooltip-title:#E1DFDF;
$cancel-grey:#898989;


$editor-background:#f8f8f8;
$desktop-background:#f0f2f3;
$arrow-background-opacity:#c2c2c22c;
$modal-background:#00000050;
$light-gray-background:#ededed;
$modal-background:#f0f0f6;
$link-modal-background:#fefefe;
$incoming-message-background:#e7eaf8;
$bottom-border-background:#e7e7e7;


$form-success:#00be19;
$form-fail:#ff0000;

$app-header-green:#07a326;
$online-green:#54ff54;

$app-header-yellow:#dea60d;


$primary-blue: #3799f4;
$sending-blue: #ebedfa;
$buttn-blue: #2699fb;
$loader-gray: #989ca0;
$temp-blue: #aec8e0;
$light-blue-border: #65b4fe;

$light-gray: #f1f1f1;
$gray: #dbdbdb;
$selected-gray: #9f9f9f;
$form-gray: #efefef;
$dark-gray: #9d9d9d;
$white-gray: #dcdcdc;
$incoming-message-gray: #f5f5f5;
$border-gray: #999;
$sub-topic-gray: #707070;
$text-gray: #4a4a4a;
$slight-gray: #aaaaaa;
$typing-text-gray: #838282;
$bottom-border-gray: #c7c7c7;
$darker-gray: #666666;
$header-gray: #a7a7a7;
$box-shadow-gray: #4d4c4c;
$light-border-gray: #b1b1b1;
$light-gray-border:#979797;
$deleted-gray:#8E8E8E;
$div-border:#BBBBBB;
$workspace-grey:#DFDFDF;
$workspace-div-title:#535353;
$gallery-add-back:#f2f2f2;


$editor-background: #f8f8f8;
$desktop-background: #f0f2f3;
$arrow-background-opacity: #c2c2c22c;
$modal-background: #00000050;
$light-gray-background: #ededed;
$modal-background: #f0f0f6;
$link-modal-background: #fefefe;
$incoming-message-background: #e7eaf8;
$bottom-border-background: #e7e7e7;

$form-success: #00be19;
$form-fail: #ff0000;

$app-header-green: #07a326;
$online-green: #54ff54;

$app-header-yellow: #dea60d;


