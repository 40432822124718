@font-face {
  font-family: bix-font;
  src: url(../../../font/SF-Pro-Display-Regular.otf);
}
.more-button {
  margin-top: 13px;
  margin-left: 10px;
}
.add-button {
  float: right;
  margin-right: -90%;
  
}