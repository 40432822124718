.body-top-bar {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  height: 6vh;
  border-bottom: #e4ebf8 2px solid;
}

.app-logo-simple {
  text-align: center;
  font: normal normal normal 25px/25px bix-font;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  float: left;
  margin-top: 0.5%;
  height: 1vh;
  margin-left: 35px;
  font-size: 19px;
}

.main-apps-div {
  width: 45%;
  margin-left: 40%;
  margin-top: 0.5%;
  left: 10%;
}

.main-apps {
  text-align: left;
  letter-spacing: 0px;
  color: #9d9d9d;
  opacity: 1;
  font-size: 12px;
  margin-top: 1.1%;
  margin-left: 0%;
  display: inline-table;
  padding-left: 4%;
  font-family: bix-font;
  font-weight: lighter;
}

.pro-pic-div-top {
  width: 8%;
  height: 6vh;
}

.pro-pic {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 55%;
  margin-top: 5px;
  background-color: #e4ebf8;
}
.pro-pic-top {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  z-index: 1;
}


/* 
.line {
  width: 93.1%;
  height: 1px;
  margin-left: 2.5%;
  background-color: rgb(185, 184, 184);
  margin-top: 5.5%;
} */
