.input-group {
  margin-top: -30px;
}
.inputGroupSubFolder {
  margin-top: -30px;
}

.popup-up {
  display: flex;
  margin-top: -28px;
}

.icon-add-folder {
  margin-left: 15px;
}



.modal-name {
  text-align: left;
  font: normal normal normal 20px/25px SF Pro Display;
  letter-spacing: 0px;
  color: #303030;
  opacity: 1;
}

.close-button {
  margin-left: 65%;
  margin-top: -7%;
  width: 10px;
  cursor: pointer;
}

.rename-box {
  width: 95%;
  height: 4.5vh;
  border-radius: 5px;
  margin-top: 4%;
  margin-left: 2.5%;
  border: none;
  font: bix-font;
  background-color: #f1f1f1;
  opacity: 1;
  padding-left: 8px;
}


.input-group-append {
  display: flex;
}

.button-popup1 {
  width: 14%;
  height: 4vh;
  background-color: #ffffff;
  border: none;
  color: black;
  font-size: 12px;
  border: #707070 1px solid;
  margin-top: 8%;
  margin-left: 68.5%;
  border-radius: 4px;
  font: bix-font;
}

.button-popup2 {
  width: 12%;
  height: 4vh;
  background-color: #3799f4;
  border: none;
  color: white;
  font: bix-font;
  border: #3799f4 1px solid;
  margin-top: 8%;
  margin-left: 4%;
  border-radius: 4px;
}

/*Delete PopUp*/

.close-button-delete {
  margin-left: 96%;
  margin-top: -7%;
  width: 10px;
  cursor: pointer;
}

.danger {
  margin-left: 147px;
  margin-top: -9px;
  width: 50px;
}

.button-popup1-delete {
  width: 25%;
  height: 3.5vh;
  background-color: #c93737;
  border: none;
  color: white;
  font-size: 12px;
  border: #707070 1px solid;
  margin-top: 1%;
  margin-left: 64.5%;
  border-radius: 4px;
  font-size: 11px;
  font: bix-font;
}

.button-popup2-delete {
  width: 25%;
  height: 3.5vh;
  background-color: #ffffff;
  border: none;
  color: black;
  font: bix-font;
  font-size: 11px;
  border: black 1px solid;
  margin-top: 1%;
  margin-left: 8%;
  border-radius: 4px;
}

.modal-name-delete {
  text-align: left;
  font: bix-font;
  letter-spacing: 0px;
  color: #303030;
  font-size: 15px;
  opacity: 1;
  font-weight: 400;
}

/*Rename Popup*/

.icon-rename {
  margin-left: 15px;
  width: 15px;
}

.modal-name-rename {
  text-align: left;
  font: bix-font;
  letter-spacing: 0px;
  color: #3799f4;
  opacity: 1;
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  margin-top: 11px;
}

.box-rename {
  width: 95%;
  height: 5vh;
  border-radius: 5px;
  margin-top: 4%;
  margin-left: 2.5%;
  border: none;
  font: bix-font;
  background-color: #f1f1f1;
  opacity: 1;
  padding-left: 8px;
}

.close-button-rename {
  margin-left: 75%;
  margin-top: -7%;
  width: 10px;
  cursor: pointer;
}
.select_container{
  display: flex;
}
