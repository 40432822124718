@import "../../color.scss";

//////////////////////////////////  Variables //////////////////////////////////////

$defultTextFont: "SF Pro Display", sans-serif;
$userNamePlaceHolder: "../../Assets/Icons/vuesax-linear-profile.svg";

::placeholder {
  font-weight: normal;
}
.login {
  .mobileUI-login {
    display: none;
  }
  ._loginContainer {
    height: 100vh;
    background-color: $white;
    display: flex;
    flex: 1;
    font-family: $defultTextFont;
    ._loginInnerContainerLeft {
      flex: 1;
      height: 100vh;
      .username_modal {
        position: absolute;
        top: 0;
        height: 100vh;
        width: 50%;
        background-color: #c4c4c48c;
        display: flex;
        .username-modal-inner {
          background-color: $white;
          width: 25rem;
          margin: auto;
          border-radius: 16px;
          padding: 4%;
          .inner-mod {
            display: flex;
            justify-content: space-between;
            .text-mod {
              font-family: "roboto";
              font-size: 1rem;
              .blu {
                color: #3799f4;
              }
            }
          }
          .ok-btn {
            background-color: $primary-blue;
            width: max-content;
            font-size: 0.875rem;
            color: $white;
            font-family: "roboto";
            padding: 3px 20px;
            border-radius: 4px;
            float: right;
            margin-top: 2rem;
            cursor: pointer;
          }
        }
      }
      .username-margin-top-login {
        margin-top: 3vh;
        margin-bottom: 5vh;
      }
      ::placeholder {
        color: $dark-gray;
        font-family: $defultTextFont;
        font-size: 16px;
      }
      .bixHeader {
        font-family: $defultTextFont;
        font-size: 36px;
        // margin: 8px auto;
        font-weight: bold;
        text-align: center;
        color: $buttn-blue;
      }
      .header-bottom-text {
        color: #9d9d9d;
        font-family: $defultTextFont;

        font-size: 0.875rem;
        margin-top: -0.5rem;
        text-align: center;
      }
      .submit-button {
        padding: 6% 2%;
        width: 80%;
        border-radius: 8px;
        background-color: $buttn-blue;
        color: $white;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        font-family: $defultTextFont;
        letter-spacing: normal;
        line-height: 0.94;
        margin-top: 6%;
        font-size: 22px;
        border-width: 0px;
        height: 50px;
        margin-left: 10%;
        margin-right: 10%;
      }
      .footerText {
        text-align: center;
        font-size: 16px;
        margin-top: 4vh;
        color: $small-text-black;
        font-family: $defultTextFont;
        font-weight: 500;
        display: flex;
        justify-content: center;
      }
      .forgetTextLink {
        width: fit-content;
        font-size: 15px;
        color: $buttn-blue;
        cursor: pointer;
        font-weight: 500;
        font-family: $defultTextFont;
      }
      .forgetText {
        margin-top: -3vh;
      }
      .footerTextLink {
        color: $buttn-blue;
        margin-left: 5px;
        cursor: pointer;
        text-decoration: underline;
      }
      .checkboxWrapper {
        margin-top: 20px;
        display: flex;
        .check-box {
          height: 18px;
          width: 18px;
          margin-right: 10px;
        }
        .checkboxLabel {
          font-family: $defultTextFont;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $text-black;
          align-items: center;
          align-self: center;
          align-content: center;
          display: flex;
        }
      }
      input.my-input:-internal-autofill-selected {
        background-color: rgb(255, 255, 255) !important;
        background-image: none !important;
        color: rgb(0, 0, 0) !important;
      }
      .wrapper {
        background: $white;
        margin: auto;
        padding: 20%;
      }

      .loginLabel {
        font-family: $defultTextFont;
        font-size: 28px;
        margin: 30px auto;
        font-weight: bold;
        text-align: left;
        color: $black;
      }

      .form-container {
        width: 100%;
        background-color: $white;
      }

      .login-form {
        position: relative;
        // display: flex;
        flex-direction: column;
      }

      .login-input-box {
        background-color: $white;
        border: none;
        font-size: 16px;
        flex: 1;
        // outline: none;
      }
      .input-container {
        display: flex;
        flex: 1;
        padding-bottom: 3px;
      }
      .fname {
        color: $dark-gray;
        font-size: 16px;
        font-family: $defultTextFont;
        width: max-content;
        
      }
      .icon {
        float: right;
        height: 16px;
        width: 16px;
        cursor: pointer;
        
        
      }
      .icon1 {
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }
      .danger-login {
        height: 12px;
        width: 12px;
        margin-right: 6px;
      }
      .inputdiv {
        border: 1px;
        border-bottom: $login-underline-gray 1px solid;
      }

      .input-label {
        pointer-events: none;
        font-size: 14px;
        color: $dark-gray;
        font-family: $defultTextFont;
      }

      .input-label :focus-within label {
        transform: translate(0, 12px) scale(0.8);
        color: #0a53e4;
      }
    }
    ._loginInnerContainerRight {
      flex: 1;
      height: 100vh;
      background-color: #3799f4;
      .wrapper2 {
        margin: auto;
        padding: 20%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login {
    ._loginContainer {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      display: table;
      
      ._loginInnerContainerLeft {

        .wrapper {
          padding: 0% 5%;
          height: 55vh;
          overflow-y: auto;
          padding-bottom: 15px;
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          margin-top: -30px;
          position: absolute;
          .bixHeader {
            display: none;
          }
          .header-bottom-text {
            display: none;
          }
        }
        .username_modal {
          position: absolute;
          height: 100vh;
          width: 100%;
          background-color: #c4c4c48c;
          z-index: 2;
          .username-modal-inner{
            margin-left: 8%;
            margin-right: 8%;
          }

        }
      }

      ._loginInnerContainerRight {
        height: 45vh;
        display: table-header-group;
        .wrapper2{
          padding: 10%;
        }
      }
    }
  }
}
