.Users-list{
    display: flex;
    padding-top: 30px;
    padding-left: 15px;
  }
  .User-info{
    padding-left: 10px;
    color: #303575;
    font-weight: bold;
    font-size: 18px;
  }
  .User{
    display: flex;
  }
  .User-tag{
    font-size: 12px;
    padding-left: 8px;
    color: #AEAEAE;
  }
  .pro-pic-top{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  z-index: 1;
  }
  .user-selector{
    position: fixed;
    left: 75%;
    padding-top: 10px;
    
  }
  .user-select{
    border-color: white;
  }
  